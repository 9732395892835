<template>
  <div class="page-content">
    <div class="container pb-3">
      <h1 class="font-weight-bold pb-5 d-none d-sm-block">{{ t('アフィリエイト') }}</h1>
      <div class="font-weight-bold pb-5 title-sm d-sm-none">{{ t('アフィリエイト') }}</div>
    </div>

    <div class="container affiliate-content p-3 bg-white">
      <div class="userinfo-title">{{ t('ERESAアフィリエイトプログラムのご案内') }}</div>
      <div class="px-4 py-2 border mb-2">
        <div class="desc-title">{{ t('ERESAアフィリエイトプログラムとは？') }}</div>
        <p class="m-0">
          {{ t('いつもERESAをご利用頂きましてありがとうございます。ERESAでは、あなたのお友達、ブログ・メルマガの読者さまにERESAをご紹介頂くことで、報酬を受け取ることが出来るアフィリエイトプログラムをご用意しております。') }}
          <span class="font-weight-bold" style="color: #3e9937">{{ t('ユーザー1名様をご紹介頂くと、ERESA PRO版（月額プラン）で796円、ERESA PRO版（年額プラン）で7,960円を契約更新毎にお支払いいたします。') }}</span>
          {{ t('是非、ご協力・ご登録をよろしくお願いいたします。') }}
        </p>
        <p>
          {{ t('※ERESA PRO会員向けのプログラムとなります。ERESA PRO会員をキャンセルしますと報酬が発生しなくなりますのでご注意ください。') }}
        </p>
        <template v-if="hasAffiliate === true">
          <div class="desc-title">{{ t('アフィリエイトURL') }}</div>
          <p v-html="t('下記URLがあなたのアフィリエイトリンクとなります。')"></p>
          <div class="d-flex flex-nowrap">
            <input class="form-control input-lg mb-4" :value="affurl" readonly>
            <b-icon icon="clipboard" id="afflink-copy" class="p-2 ml-1 mt-1 h2" @click="copy"></b-icon>
            <b-tooltip ref="tooltipCopy" target="afflink-copy" :show.sync="tooltipCopy" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
          </div>
        </template>
        <div v-else-if="hasAffiliate === false" class="text-center">
          <b-button v-if="subscriber === true" @click="register" class="btn-sm bg-eresa">{{ t('ERESAアフィリエイトプログラムに参加する') }}</b-button>
          <lock-pro-button v-else-if="subscriber === false" text="ERESAアフィリエイトプログラムに参加する"></lock-pro-button>
          <p v-if="hasErrorRegister"><span class="error-message">{{ t('登録に失敗しました。') }}</span></p>
        </div>
      </div>

      <div v-if="hasAffiliate === true" class="row mb-2">
        <div class="col-sm-5">
          <div class="userinfo-title">{{ t('報酬振込先口座') }}</div>
          <div class="px-4 py-2 border d-flex flex-column">
            <label class="control-label">{{ t('銀行名 / 銀行コード') }}
              <div class="grid-bank">
                <div><Select2 v-model="selectedBank" :options="bankList" :settings="bankSettings" :placeholder="t('銀行名を選択')" class="affiliate-ctrl" @select="bankSelected" /></div>
                <div class="affiliate-ctrl ml-2">{{ selectedBank == void 0 ? '-' : selectedBank }}</div>
              </div>
              <span v-if="hasErrorBank" class="error-message">{{ t('{0}を正しく入力してください。', [t('銀行名 / 銀行コード')]) }}</span>
            </label>
            <label class="control-label">{{ t('支店名 / 支店コード') }}
              <div class="grid-bank">
                <div><Select2 v-model="selectedBankBranch" :options="bankBranchList" :settings="bankBranchSettings" :placeholder="t('支店名を選択')" class="affiliate-ctrl" @select="bankBranchSelected" /></div>
                <div class="affiliate-ctrl ml-2">{{ selectedBankBranch == void 0 ? '-' : selectedBankBranch }}</div>
              </div>
              <span v-if="hasErrorBankBranch" class="error-message">{{ t('{0}を正しく入力してください。', [t('支店名 / 支店コード')]) }}</span>
            </label>
            <label class="control-label">{{ t('口座種類') }}
              <b-form-radio-group v-model="selectedAccountType" :options="accountTypes" />
              <span v-if="hasErrorAccountTypes" class="error-message">{{ t('{0}を正しく選択してください。', [t('口座種類')]) }}</span>
            </label>
            <label class="control-label">{{ t('口座番号') }}
              <b-form-input maxlength="10" v-model="accountNumber" placeholder="1234567" class="affiliate-ctrl"></b-form-input>
              <span v-if="hasErrorAccountNumber" class="error-message">{{ t('{0}を正しく入力してください。', [t('口座番号')]) }}</span>
            </label>
            <label class="control-label">{{ t('口座名義') }}
              <b-form-input maxlength="100" v-model="accountName" :placeholder="accountNamePlaceholder" class="affiliate-ctrl"></b-form-input>
              <span v-if="hasErrorAccountName" class="error-message">{{ t('{0}を正しく入力してください。', [t('口座名義')]) }}</span>
            </label>
            <label class="control-label">{{ t('インボイス発行事業者登録番号') }}
              <b-form-input maxlength="20" v-model="invoiceRegistrationNumber" class="affiliate-ctrl" :disabled="noInvoiceRegistrationNumber"></b-form-input>
              <span v-if="hasErrorInvoiceRegistrationNumber" class="error-message">{{ t('{0}を正しく入力してください。', [t('インボイス発行事業者登録番号')]) }}</span>
              <b-form-checkbox v-model="noInvoiceRegistrationNumber">{{ t('インボイス発行事業者ではありません') }}</b-form-checkbox>
            </label>
            <b-form-checkbox v-model="checkTerms" class="mt-4"><div v-html="terms"></div></b-form-checkbox>
            <b-button @click="updateAccount" class="btn-sm bg-eresa" :disabled="!checkTerms"><font-awesome-icon icon="fa-solid fa-repeat" /> {{ t('口座情報を更新する') }}</b-button>
            <p v-if="hasSuccessUpdateAccount"><span class="success-message">{{ t('登録に成功しました。') }}</span></p>
            <p v-if="hasErrorUpdateAccount"><span class="error-message">{{ t('登録に失敗しました。') }}</span></p>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="userinfo-title">{{ t('報酬条件・成約状況') }}</div>
          <div class="px-4 py-2 border">
            <div class="table-responsive">
              <table class="table table-striped table-center table-hover">
                <thead>
                  <tr>
                    <th class="text-center">{{ t('プラン') }}</th>
                    <th class="text-right">{{ t('報酬') }}</th>
                    <th class="text-right">{{ t('成約数 / 紹介無料会員') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in commissionList" :key="`commission-${i}`">
                    <td>{{ item.plan_name }}</td>
                    <td class="text-right"><strong>{{ item.commission | number }}{{ item.period === 'y' ? t('円 / 1年') : t('円 / 1ヶ月') }}</strong></td>
                    <td class="text-right"><strong>{{ item.active_count | number }}{{ t('アカウント') }}</strong> / {{ item.total_count | number }}{{ t('アカウント') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="userinfo-title">{{ t('報酬履歴') }}</div>
          <div class="px-4 py-2 border">
            <div class="font-weight-bold">{{ t('残高') }}</div>
            <div class="font-weight-bold text-center pb-3" style="font-size: 1.25rem"><font-awesome-icon icon="fa-solid fa-jpy" />{{ balance | number }}</div>

            <div class="font-weight-bold">{{ t('入出金履歴') }}</div>
            <div class="table-responsive">
              <table class="table table-striped table-vcenter table-hover">
                <thead>
                  <tr>
                    <th>{{ t('入出金日') }}</th>
                    <th class="text-right">{{ t('入出金額') }}</th>
                    <th>{{ t('摘要') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in balanceDetail" :key="`balanceDetail-${i}`">
                    <td>{{ item.date }}</td>
                    <td :class="item.amount < 0 ? 'text-right text-danger' : 'text-right'">{{ item.amount | number }}{{ t('円') }}</td>
                    <td>{{ item.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 bg-white border">
        <font-awesome-icon icon="fa-solid fa-info-circle" /> <span class="font-weight-bold">{{ t('特筆事項') }}</span>
        <ul>
          <li>{{ t('報酬は毎月1日に前月の成約数に基づいて確定し、報酬残高に追加されます。確定した報酬の振込は翌月の10日に行われます。（例：10月分のアフィリエイト報酬は11月1日に確定し、12月10日に振込が行われます。）') }}</li>
          <li>{{ t('口座情報に誤りがある場合は組み戻しとなります。その際の振込手数料は返金致しません。') }}</li>
          <li>{{ t('残高が5,000円未満の場合お振り込みせず、翌月へ持ち越しとなります。') }}</li>
          <li>{{ t('振込手数料は270円です。') }}</li>
          <li>{{ t('決済時にクーポンが適用されることで報酬額が減額されることがあります。') }}</li>
          <!-- <li>{{ t('インボイス発行事業者登録番号を記入されていない場合、アフィリエイト報酬から10%を差し引いた金額のお振込みとなります。') }}</li> -->
        </ul>
      </div>
    </div>
    <confirm-password ref="confirmPassword"></confirm-password>
    <b-modal ref="affConfirm" @ok="affConfirmed" :okTitle="t('はい')" :cancelTitle="t('いいえ')">
      <div class="mt-4">
        <div>{{ t('ERESAアフィリエイトプログラムに参加しますか？') }}</div>
        <div class="mt-2">
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import Select2 from 'v-select2-component';
import AuthUtil from '@/mixins/authutil';
import Utils from '@/mixins/utils';
import ConfirmPassword from '@/components/ConfirmPassword.vue';
import LockProButton from '@/components/LockProButton.vue';

export default {
  name: 'Affiliate',
  filters: {
    number(val) {
      return Number(val).toLocaleString();
    },
  },
  data() {
    return {
      hasAffiliate: null,
      affurl: null,
      balance: 0,
      balanceDetail: [],
      commissionList: [],
      bankSettings: null,
      bankList: [],
      selectedBank: null,
      selectedBankName: null,
      bankBranchSettings: null,
      bankBranchList: [],
      selectedBankBranch: null,
      selectedBankBranchName: null,
      accountNumber: '',
      accountName: '',
      accountNamePlaceholder: this.t('ヤマダ　タロウ'),
      accountTypes: [{value: '普通', text: this.t('普通')}, {value: '当座', text: this.t('当座')}, {value: '貯蓄', text: this.t('貯蓄')}],
      selectedAccountType: null,
      invoiceRegistrationNumber: '',
      noInvoiceRegistrationNumber: false,
      terms: this.t('<a href="https://eresa.jp/affiliate-terms/" target="_blank">ERESAアフィリエイトセンター会員利用規約</a>に同意して登録します。'),
      checkTerms: false,
      hasErrorRegister: false,
      hasSuccessUpdateAccount: false,
      hasErrorUpdateAccount: false,
      hasErrorBank: false,
      hasErrorBankBranch: false,
      hasErrorAccountTypes: false,
      hasErrorAccountNumber: false,
      hasErrorAccountName: false,
      hasErrorInvoiceRegistrationNumber: false,
      tooltipCopy: false,
      subscriber: null,
    };
  },
  mixins: [AuthUtil, Utils],
  components: {
    Select2,
    ConfirmPassword,
    LockProButton,
  },
  async created() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
    if (!this.subscriber) {
      this.hasAffiliate = false;
      return;
    }
    this.setupBank();
    await this.init();
  },
  methods: {
    async init() {
      const r = await API.graphql(graphqlOperation(mutations.affiliateOperation, { operation: 'read' }));
      const data = JSON.parse(r.data.affiliateOperation);
      this.bindData(data);
    },
    bindData(data) {
      this.hasAffiliate = data.hasAffiliate;
      if (this.hasAffiliate) {
        this.affurl = `${location.protocol}//${location.hostname}/register?aff_id=${data.affId}`;
        this.balance = data.balance;
        this.balanceDetail = data.balanceDetail;
        this.commissionList = data.commissionList;
        this.bankList = [{ id: data.bankInfo.bankCode, text: data.bankInfo.bankName }];
        this.selectedBank = data.bankInfo.bankCode;
        this.selectedBankName = data.bankInfo.bankName;
        this.bankBranchList = [{ id: data.bankInfo.bankBranchCode, text: data.bankInfo.bankBranchName }];
        this.selectedBankBranch = data.bankInfo.bankBranchCode;
        this.selectedBankBranchName = data.bankInfo.bankBranchName;
        this.accountNumber = data.bankInfo.bankNumber;
        this.accountName = data.bankInfo.bankAccountName;
        this.invoiceRegistrationNumber = data.bankInfo.invoiceRegistrationNumber;
        this.selectedAccountType = data.bankInfo.bankKind;
        if (this.invoiceRegistrationNumber == void 0) {
          this.noInvoiceRegistrationNumber = this.selectedBank != void 0;
        }
      }
    },
    setupBank() {
      const getQuery = this.getQuery;
      this.bankSettings = {
        ajax: {
          url: 'https://bank.teraren.com/banks/search.json',
          dataType: 'json',
          quietMillis: 250,
          data: t => getQuery(t.term),
          processResults: data => ({
            results: data.map(obj => {
              if (obj.code < 1000) {
                obj.name += '銀行';
              }
              return { id: obj.code, text: obj.name };
            })
          }),
          cache: true
        },
        minimumInputLength: 1,
      };
      this.bankBranchSettings = {
        ajax: {
          url: () => `https://bank.teraren.com/banks/${this.selectedBank}/branches/search.json`,
          dataType: 'json',
          quietMillis: 250,
          data: t => {
            let term = t.term;
            const bankCode = this.selectedBank;
            // ゆうちょの場合は数字を漢数字に直す
            if(bankCode === '9900') {
              // まず半角に
              term = term.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
              const txt = new Array('〇', '一', '二', '三', '四', '五', '六', '七', '八', '九');
              let res = '';
              for (let i = 0; i < term.length; i++) {
                const idx = term.charAt(i);
                if (isFinite(idx)) {
                  res += txt[eval(idx)];
                }
              }
              term = res;
            }
            return getQuery(term);
          },
          processResults: data => ({
            results: data.map(obj => {
              return { id: obj.code, text: obj.name };
            })
          }),
          cache: true
        },
        minimumInputLength: 1,
      };
    },
    async register() {
      this.hasErrorRegister = false;
      if (await this.isSns()) {
        this.$refs.affConfirm.show();
      }
      else {
        this.$refs.confirmPassword.show(async () => await this.affConfirmed());
      }
    },
    async affConfirmed() {
      this.hasErrorRegister = false;
      try {
        const r = await API.graphql(graphqlOperation(mutations.affiliateOperation, { operation: 'create' }));
        const data = JSON.parse(r.data.affiliateOperation);
        this.bindData(data);
      }
      catch(e) {
        this.hasErrorRegister = true;
        throw e;
      }
    },
    async updateAccount() {
      this.hasSuccessUpdateAccount = false;
      this.hasErrorUpdateAccount = false;
      this.hasErrorBank = false;
      this.hasErrorBankBranch = false;
      this.hasErrorAccountTypes =  false;
      this.hasErrorAccountNumber = false;
      this.hasErrorAccountName = false;
      this.hasErrorInvoiceRegistrationNumber = false;
      let hasErrors = false;
      if (this.selectedBank == void 0 || !this.selectedBank.match(/^[0-9]{4}$/)) {
        this.hasErrorBank = true;
        hasErrors = true;
      }
      if (this.selectedBankBranch == void 0 || !this.selectedBankBranch.match(/^[0-9]{3}$/)) {
        this.hasErrorBankBranch = true;
        hasErrors = true;
      }
      if (this.selectedAccountType == void 0) {
        this.hasErrorAccountTypes = true;
        hasErrors = true;
      }
      if (this.accountNumber == void 0 || !this.accountNumber.match(/^[0-9]+$/)) {
        this.hasErrorAccountNumber = true;
        hasErrors = true;
      }
      if (this.accountName == void 0 || this.accountName == '') {
        this.hasErrorAccountName = true;
        hasErrors = true;
      }
      if (!this.noInvoiceRegistrationNumber) {
        if (this.invoiceRegistrationNumber == void 0 || this.invoiceRegistrationNumber == '') {
          this.hasErrorInvoiceRegistrationNumber = true;
          hasErrors = true;
        }
      }
      if (hasErrors) {
        return;
      }
      const bankInfo = {
        bankName: this.selectedBankName,
        bankCode: this.selectedBank,
        bankBranchName: this.selectedBankBranchName,
        bankBranchCode: this.selectedBankBranch,
        bankKind: this.selectedAccountType,
        bankNumber: this.accountNumber,
        bankAccountName: this.accountName,
        invoiceRegistrationNumber: this.noInvoiceRegistrationNumber ? null : this.invoiceRegistrationNumber
      };
      try {
        await API.graphql(graphqlOperation(mutations.affiliateOperation, { operation: 'update', args: JSON.stringify(bankInfo) }));
      }
      catch(e) {
        this.hasErrorUpdateAccount = true;
        throw e;
      }
      this.hasSuccessUpdateAccount = true;
    },
    bankSelected(e) {
      this.selectedBankName = e.text;
      this.bankBranchList = [];
      this.selectedBankBranch = null;
      this.selectedBankBranchName = null;
    },
    bankBranchSelected(e) {
      this.selectedBankBranchName = e.text;
    },
    getQuery(key){
      if(key.match(/[\u4E00-\u9FFF]/)) {
        // 漢字含まれる
        return {
          name: key
        };
      }
      else if(key.match(/[A-Za-zＡ-Ｚａ-ｚ]/)) {
        // 英字含まれる、全角に直す
        return {
          name: key.replace(/[A-Za-z]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
          })
        };
      }
      else {
        // カタカナに変換
        return {
          kana: key.replace(/[\u3041-\u3096]/g, function(match) {
            var chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
          })
        };
      }
    },
    copy() {
      // ※クリップボードはhttpsのみ動作
      navigator.clipboard.writeText(this.affurl).catch((e) => console.error(e));
      setTimeout(() => this.$refs.tooltipCopy.show = false, 1000);
    },
  },
};
</script>

<style scoped>
.title-sm {
  font-size: 20px;
}

.desc-title {
  font-weight: bold;
  border-bottom: solid 1px #ccc;
  margin-top: 4px;
  margin-bottom: 4px;
}

.userinfo-title {
  font-weight: bold;
  background-color: #376f99;
  color: #fff;
  border-bottom: solid 1px #ccc;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.grid-bank {
  display: grid;
  grid-template-columns: 1fr 4em;
}

.table td, .table th {
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.success-message {
  color: blue;
}

.error-message {
  color: red;
}

.affiliate-content, .btn-sm, .affiliate-ctrl {
  font-size: 0.8rem;
}

@media (max-width: 575.98px) {
  .affiliate-content, .btn-sm, .affiliate-ctrl {
    font-size: 0.75rem;
  }
}
</style>
